import env from '../env';

/**
 * Join two or more paths together, while removing excessive slashes.
 */
export const join = (...paths: string[]): string =>
  '/' +
  paths
    .join('/')
    .replace(/\/\.\//, '/') // Replace dots like /./ with a single slash
    .replace(/\/{2,}/g, '/') // Replace repeated slashes with a single slash
    .replace(/^\//g, ''); // Replace leading slashes

export const removeHostedBasePath = (path: string) => path.replace(/^\/pr-[0-9]+\/?/, '/');

/**
 * Removes query string and hash from path, never return empty string. See specs for more
 * details.
 */
export const sanitizePath = (path: string): string => path.replace(/(\?|#).*$/, '') || '/';

export const toURL = (path: string) => (env('HOST') ? new URL(path, env('HOST')).href : path);
