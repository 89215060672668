import { useState, useEffect } from 'react';

const cachedScripts: string[] = [];

interface ScriptOptions {
  async: boolean;
  defer: boolean;
}

const defaultScriptOptions = {
  async: true,
  defer: false,
};

function useScript(src: string, id?: string, options?: Partial<ScriptOptions>): [boolean, boolean] {
  const scriptOptions = { ...defaultScriptOptions, ...options };

  const [state, setState] = useState({
    loaded: cachedScripts.includes(src),
    error: false,
  });

  useEffect(() => {
    if (cachedScripts.includes(src)) {
      setState({
        loaded: true,
        error: false,
      });
    } else {
      cachedScripts.push(src);

      const script = document.createElement('script');
      script.src = src;
      script.async = scriptOptions.async;
      script.defer = scriptOptions.defer;
      if (id) {
        script.id = id;
      }

      const onScriptLoad = () => {
        setState({
          loaded: true,
          error: false,
        });
      };

      const onScriptError = () => {
        const index = cachedScripts.indexOf(src);
        if (index >= 0) cachedScripts.splice(index, 1);
        script.remove();

        setState({
          loaded: true,
          error: true,
        });
      };

      script.addEventListener('load', onScriptLoad);
      script.addEventListener('error', onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener('load', onScriptLoad);
        script.removeEventListener('error', onScriptError);
      };
    }
  }, [src, id]);

  return [state.loaded, state.error];
}

export { useScript };
