export const PREFIX = 'FE';
export const GLOBAL_KEY = '__ENV';

type Environment = {
  [key: string]: string;
};

declare global {
  interface Window {
    [GLOBAL_KEY]: Environment;
  }
}

const prefixMatch = new RegExp(`^${PREFIX}_`);
// @todo The environment is being read by vanilla extract compilation (while retrieving the static path prefix). Unfortunately,
//  that errors on window being undefined (even though specifically checking for it). For an unknown reason, checking
//  global.window does work. This is a (permanent) temporary workaround.
const isBrowser = typeof global.window !== 'undefined';
const TRUE_VALUES = ['1', 'y', 'yes', 'true'];
const FALSE_VALUES = ['0', 'n', 'no', 'false'];

export const read = (): Environment => {
  const keys = Object.keys(process.env).filter(key => prefixMatch.test(key));

  return keys.sort().reduce(
    (env, key) => {
      env[key] = process.env[key] as string;
      return env;
    },

    {} as Environment,
  );
};

// TODO: Create TS utils?
type AnyStringExcept<T extends string, NotAllowed> = T extends NotAllowed ? never : T;

function env(): Environment;
function env<T extends string>(key: AnyStringExcept<T, 'NODE_ENV'>): string;
function env<T extends string>(key?: AnyStringExcept<T, 'NODE_ENV'>): Environment | string {
  if (!key) return isBrowser ? window[GLOBAL_KEY] : read();
  const safeKey = `${PREFIX}_${key}`;

  if (!isBrowser) return process.env[safeKey] ?? process.env[key] ?? '';

  if (GLOBAL_KEY in window) {
    return window[GLOBAL_KEY][safeKey];
  }

  return process.env[safeKey] ?? '';
}

export function envBoolean<T extends string>(
  key: AnyStringExcept<T, 'NODE_ENV'>,
  defaultValue?: boolean,
): boolean | undefined {
  const val = env(key)?.toLowerCase();
  if (val) {
    if (TRUE_VALUES.includes(val)) return true;
    if (FALSE_VALUES.includes(val)) return false;
  }
  return defaultValue;
}

export const getStaticPrefix = (): string => env('STATIC_PREFIX') ?? '';

export default env;
