import { createContext, useContext, useMemo } from 'react';

import { useRouter as useNextRouter, NextRouter } from 'next/router';

import type { Locale, Language, CountryCode, I18n } from '@common/application';
import { sanitizePath, join } from '@common/path';

type RouterValue = {
  routerBasePath: string;
  activeRoute: null | string;
};

export const RouterContext = createContext<RouterValue>({
  routerBasePath: '',
  activeRoute: null,
});

export const useRouterPath = () => useContext(RouterContext);

export interface UseRouter {
  activeRoute: null | string;
  activePath: string;
  asPath: NextRouter['asPath'];
  basePath: NextRouter['basePath'];
  events: NextRouter['events'];
  isReady: NextRouter['isReady'];
  locale: Locale;
  locales: Locale[];
  replace: NextRouter['replace'];
  reload: NextRouter['reload'];
  push: NextRouter['push'];
  pushHref: NextRouter['push'];
  query: NextRouter['query'];
  routerBasePath: string;
}

export const useLocales = (countryCode: CountryCode): I18n => {
  const { locale: language, locales: languages } = useNextRouter() as { locale: Language; locales: Language[] };
  const locale = `${language}-${countryCode}` as Locale;
  const locales = (languages ?? []).map(language => `${language}-${countryCode}`) as Locale[];
  return {
    language,
    languages,
    locale,
    locales,
    countryCode,
  };
};

export const useRouter = (): UseRouter => {
  const { routerBasePath, activeRoute } = useRouterPath();
  const router = useNextRouter();

  return useMemo(() => {
    const { basePath, asPath, query, defaultLocale, locale } = router;
    const localePrefix = locale === defaultLocale ? '' : `/${locale}`;
    const activePath = basePath + localePrefix + sanitizePath(asPath);
    const unbase = (path: string): string => (basePath ? path.replace(new RegExp('^' + basePath), '') : path);

    // Push or replace path within (basePath) container and/or <Router> context
    const normalize = (path: string): string => (routerBasePath ? join(unbase(routerBasePath), path) : unbase(path));

    const push = (path: string) => router.push(normalize(path));
    const replace = (path: string) => router.replace(normalize(path));
    const reload = () => router.reload();

    // Push any href verbatim (although Next.js requires /basePath removed within container)
    const pushHref = (path: string) => {
      if (path.startsWith('http')) return router.push(path);
      return router.push(unbase(path));
    };

    return {
      activePath,
      activeRoute,
      asPath,
      basePath,
      events: router.events,
      isReady: router.isReady,
      locale: router.locale as Locale,
      locales: router.locales as Locale[],
      replace,
      reload,
      push,
      pushHref,
      query,
      routerBasePath,
    };
  }, [router, routerBasePath, activeRoute]);
};
