import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { ChatArgs, ChatContext, ChatContextValue, Namespace } from '@components/chat/useChat';
import { useRouter } from '@dxp-next';
import { useMachine } from '@eneco-packages/xstate-custom/xstate-react';

import chatMachine, { ChatEvent } from './ChatMachine';
import * as Seamly from './Seamly';
import { useScript } from './useScript';

export type SeamlyConfig = {
  defaultNamespace: Namespace;
  defaultTopic: string;
  instance?: string;
  defaultLocale?: string;
  userLocale?: string;
};

type Props = {
  children: React.ReactNode;
  chatSrc?: string;
  config?: SeamlyConfig;
};

export const ChatProvider: FC<Props> = ({ children, chatSrc, config }) => {
  const [state, send] = useMachine(chatMachine);
  const [script, setScript] = useState('');
  const chatState = typeof state.value === 'object' ? Object.values(state.value) : state.value;
  const router = useRouter();

  // TODO This is where can handle Conversational CDN outages
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- exemplary for TODO above
  const [loaded, error] = useScript(script, Seamly.srcId, { async: false, defer: true });

  const sendEvent = useCallback(
    ({
      api,
      askText,
      context,
      layoutMode = 'window',
      namespace = config?.defaultNamespace ?? 'consumer',
      parentElement,
      topic = config?.defaultTopic,
      variables,
      visibility = 'minimized',
    }: ChatArgs = {}) => {
      if (config?.defaultLocale) {
        context = {
          ...context,
          locale: config.defaultLocale,
          userLocale: config.userLocale,
        };
      }

      send({
        api,
        askText,
        context,
        instance: config?.instance,
        namespace,
        parentElement,
        topic,
        type: `${layoutMode}:${visibility}`,
        variables,
      } as ChatEvent);
    },
    [config?.defaultLocale, config?.defaultNamespace, config?.defaultTopic, config?.instance, send],
  );

  const mount = useCallback(sendEvent, [sendEvent]);
  const value = useMemo<ChatContextValue>(() => [mount], [mount]);

  useEffect(() => {
    const handleRouteChange = () => {
      if (chatState?.includes('open')) {
        sendEvent({ visibility: 'minimized' });
      }
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- should act as mount/unmount useEffect only
  }, []);

  useEffect(() => {
    if (chatState !== 'idle') {
      setScript(chatSrc ?? Seamly.src);
    }
  }, [chatSrc, chatState]);

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
